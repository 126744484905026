<template>
	<div style="background: #f1f1f1;">
		<div class="flex nav flex-row-around">
			<div class="blocks">
				<div class="yellow">{{$t("newadd.t.当前剩余设计点")}}</div>
				<div class="green">{{userInfo.designPoints}}</div>
				<div class="gray">( {{$t("newadd.t.每购买500元货品，赠送1个设计点数")}})</div>
				<div class="" @click="go_recharge">{{$t("newadd.t.点击充值")}}
					<i class="el-icon-arrow-right"></i>
				</div>
			</div>

		</div>

		<!-- 商品信息 -->
		<el-table :data="tableData" style="width: 100%;margin-top: 20px;">
			<el-table-column  :label="$t('newadd.t.状态')">
				<template v-slot="scope">
					{{$lang(scope.row, "description")}}
				</template>
			</el-table-column>
			<el-table-column prop="money"  :label="$t('newadd.t.金额')">
			</el-table-column>
			<el-table-column prop="createTime"  :label="$t('newadd.t.时间')">
			</el-table-column>
		
		</el-table>




	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	export default {
		data() {
			return {

				info: {
					level: "5454"

				},
				tableData: []
			}
		},
		computed: {
			...mapState(["userInfo"])
		},
		created() {
			this.$req.get("/client/im/user/wallet/moneyList", {
				current:1,
				module:2
			}).then(async res => {
				this.tableData=res.records
			        
			})
		
		},
		methods: {
			open() {
				this.$alert(
					this.$t("inviteCode.mesAlertContent"),
					this.$t("inviteCode.mesAlertTitle"), {
						confirmButtonText: this.$t("inviteCode.mesAlertConfirm"),
						callback: (action) => {},
					}
				);


			},
			go_recharge() {
				this.$router.push('/recharge')
			}
		}

	}
</script>

<style scoped="scoped" lang="scss">
	.nav {

		height: 200px;
		background: #3296fa;
		border: 1px solid #D6D6D6;

		.blocks {
			text-align: center;
			color: #fff;
			height: 180px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			.yellow {
				color: #fde720;
			}

			.green {
				color: #3ae03d;
				font-size: 54px;
			}

			.gray {
				color: #c9c8c8;
			}
		}


	}
</style>
